import React, { useState, useEffect } from 'react'
import 'style/layout.scss'
import css from './style.module.scss'
import { userSession } from 'utils/auth'
import { Link } from '@reach/router'
import { getUser } from 'utils/auth'
// import { Switch, Route } from 'react-router-dom'
import Button from 'components/Button'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import BottomBar from 'components/ui/BottomBar'
import cn from 'classnames'
const Header = () => {
  const user = getUser()
  const onLogout = () => {
    userSession.signUserOut('/')
  }
  return (
    <div className={css.header}>
      <h1>
        <Link to="/">
          {/* <img
            className={css.logo}
            alt="Cozy Reader Logo"
            src={withPrefix(`/cozy.logo.100.png`)}
          /> */}
          <span>Atoms.</span>
        </Link>
      </h1>
      {user.profile && (
        <div className={css.right}>
          <span>Hi {user.profile.name} (</span>
          <Button link onClick={onLogout}>
            Logout
          </Button>
          <span>)</span>
        </div>
      )}
    </div>
  )
}

export const Back = () => {
  return (
    <Button link to="/" className={css.back}>
      Return to list
    </Button>
  )
}

const data = {
  title: 'Atoms: the path to a new you.',
  description: 'Atoms helps you form new habits to achieve your goals.',
}

const Head = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Atoms</title>
    <meta name="title" content={data.title} />
    <meta name="description" content={data.description} />
    <meta itemprop="image" content="https://useatoms.xyz/meta_image.png" />
    <meta itemprop="name" content={data.title} />
    <meta itemprop="description" content={data.description} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={process.env.GATSBY_URL} />
    <meta property="og:title" content={data.title} />
    <meta property="og:description" content={data.description} />
    <meta property="og:image" content="https://useatoms.xyz/meta_image.png" />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content={process.env.GATSBY_URL} />
    <meta property="twitter:title" content={data.title} />
    <meta property="twitter:description" content={data.description} />
    <meta name="twitter:image" content="https://useatoms.xyz/meta_image.png" />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href={`/favicons/apple-touch-icon.png`}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href={`/favicons/android-chrome-192x192.png`}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="512x512"
      href={`/favicons/android-chrome-512x512.png`}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href={`/favicons/favicon-32x32.png`}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href={`/favicons/favicon-16x16.png`}
    />
    <link rel="manifest" href={`/favicons/manifest.json`} />
  </Helmet>
)

const Page = ({ homepage, children, withHeader = true }) => {
  return (
    <>
      <Head />
      <div className={css.pageContainer}>
        <div className={cn(css.page, homepage && css.homepage)}>
          {withHeader && <Header />}
          {children}
        </div>
      </div>
    </>
  )
}

export default Page
